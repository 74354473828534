import React from 'react';
import {Root} from './lib/root.js';

import './App.css';

function App() {
  return (
   <Root/>
  );
}

export default App;
