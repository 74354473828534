import { Breadcrumbs, Button, Card, CardContent, CardMedia, CircularProgress, Divider, Link, TextField, Typography } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

const breadcrumbsVar = [
    {href: "#", ctx: "Home"},
    {href: "#", ctx: "About"}
]
const url = 'http://mastodon.rf.gd/';

export const Root =()=>{
    const [ currentPage, change ]=useState(true);
    const [ page, setPage]       = useState(1);
    const [ maxPage, setMaxPage ]= useState(1);
    const [ data, setData ]      = useState(null);
    const [ loading, setLoading] = useState(true);

    const pageCt = useCallback(()=>{
        axios.post( url + 'page.php')
        .then(res=> setMaxPage(res.data))
    },[]);

    const post = useCallback(()=> {
        const req = { p : page};

        axios.post(url + 'post.php', req)
        .then( res => setData(res.data))
        .then(()=>setLoading(false))
    },[page]);

    useEffect(()=>{
        post();
        pageCt();
    },[post, pageCt]);

    const decrement=()=>{
        setPage(page - 1 );
        if(page <= 1){
            setPage(1);
        }
    }

    const increment=()=>{
        setPage(page + 1 );
    }
    const handleCtx=(e)=>{
        if(e.target.name === "About"){
            change(false);
        }else{
            change(true);
        }
    }
    if(loading){
        return (
            <div style={{justifyContent:'center', alignItems:'center', display:'flex',height: '100vh'}}>
                <CircularProgress/>             
            </div>)
    }

    return(
        <div style={{justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column', marginTop: 15}}>
            {currentPage ? <div style={{justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column'}}>
            <Breadcrumbs separator="-" style={{borderBottom:'1px solid rgba(0,0,0,0.12)'}}>
                {breadcrumbsVar.map((item, index)=>{
                    return(
                    <Link 
                        key={index} 
                        href={item.href} 
                        name={item.ctx} 
                        color={currentPage === false || item.ctx === "Home" ? 'primary' : 'inherit' } 
                        onClick={handleCtx}>{item.ctx}</Link>)
                })}
            </Breadcrumbs>
            <Divider/>
            <div style={{flexDirection: 'row', display: 'flex'}}>
                <Button disabled={page === 1 ? true: false} onClick={decrement}><ArrowLeft/></Button>
                <Typography variant="h2" component='p' align='center'>{page}/{maxPage}</Typography>
                <Button disabled={page === maxPage ? true : false } onClick={increment}><ArrowRight/></Button>
            </div>
            <div>
                {data.slice(0).reverse().map( (item,index)=>{
                    return(
                        <Card key={index} style={{marginBottom: 5, width: 300}}>
                            <CardMedia image={item.image} style={{height: 0, width: 300, paddingTop:'120%'}}/>
                            <CardContent>
                                <Typography color="textSecondary" variant="subtitle2" component="p">{item.date}</Typography>
                                <Typography color="textSecondary" variant="body2" component="p">{item.text}</Typography>
                            </CardContent>
                        </Card>
                    )
                })}
                </div> 
            </div>:
            <div style={{justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column'}}>
                <Breadcrumbs separator="-" style={{borderBottom:'1px solid rgba(0,0,0,0.12)'}}>
                {breadcrumbsVar.map((item, index)=>{
                    return(
                    <Link 
                        key={index} 
                        color={currentPage || item.ctx === "Home" ? 'inherit' : 'primary'} 
                        href={item.href} 
                        name={item.ctx} 
                        onClick={handleCtx}>{item.ctx}
                    </Link>)
                })}
                </Breadcrumbs>
                <Divider/>
                <div style={{display:'flex',marginTop: 30}}>
                    <Typography component='p' variant='h6' style={{color:'#0099ff'}}>PN</Typography>
                    <TextField style={{marginLeft: 55}}value="touwa"/>
                </div>
                <Typography component='p' variant='h6' style={{color:'#0099ff',margin:'30px 0'}}><u>Contact</u></Typography>
                <div><Typography component='p' variant='h6' style={{color:'limegreen'}}>Fiverr</Typography><TextField value="fiverr.com/seiru18"/></div>
                <div>
                    <div style={{display:'flex'}}>
                    <Typography component='p' variant='h6' style={{color:'#4285F4'}}>G</Typography>
                    <Typography component='p' variant='h6' style={{color:'#EA4335'}}>m</Typography>
                    <Typography component='p' variant='h6' style={{color:'#FBBC05'}}>a</Typography>
                    <Typography component='p' variant='h6' style={{color:'#4285F4'}}>i</Typography>
                    <Typography component='p' variant='h6' style={{color:'#34A853'}}>l</Typography>
                    </div>
                    <TextField value="nick.avm@gmail.com"/>
                </div>
            </div>}
        </div>
    );
}